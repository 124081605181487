import SectionTitle from './SectionTitle.js';
import FeaturesCard from './FeaturesCard.js';

const FeaturesSection = () => {
	const styles = {
		section: {
			color: '#fff'
		},
		card_row: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: window.innerWidth <= 700 ? '' : '1.25rem',
			flexDirection: window.innerWidth <= 700 ? 'column' : 'row'
		}
	};

	return (
		<div style={styles.section} className="section">
			<SectionTitle first={true}>Features</SectionTitle>
			<div style={styles.card_row} className="features-card-row">
				<FeaturesCard title="Fast Monitors" iconPath="/icons/bolt.svg">
					Never miss a drop or restock. We have you covered with fast monitors for a plethora of websites.
				</FeaturesCard>
				<FeaturesCard title="PE Services" iconPath="/icons/people.svg">
					A second Discord server included in all memberships that offering high discounts on products and
					services.
				</FeaturesCard>
				<FeaturesCard title="24/7 Support" iconPath="/icons/info.svg">
					Our support team is available to help you anyway they can. Reach out via Discord, Twitter,
					Instagram, or TikTok.
				</FeaturesCard>
			</div>
			<div style={styles.card_row} className="features-card-row">
				<FeaturesCard title="Exclusive Deals" iconPath="/icons/bell.svg">
					Offering the most exclusive price errors, glitches, and deals in the industry. The winning team will
					never miss!
				</FeaturesCard>
				<FeaturesCard title="Lowkey Flips" iconPath="/icons/money.svg">
					Categories include NFTs, Toys, Video Games, Cards, Music, Amazon FBA, Drop Shipping & more!
				</FeaturesCard>
				<FeaturesCard title="Investments" iconPath="/icons/chart.svg">
					Advisor services for credit building, debt, real estate, travel, and accounting to help your journey
					to financial freedom!
				</FeaturesCard>
			</div>
		</div>
	);
};

export default FeaturesSection;
