const SectionTitle = (props) => {
	const styles = {
		title: {
			textTransform: 'uppercase',
			textAlign: 'center',
			fontSize: '2.5rem',
			marginTop: window.innerWidth <= 700 && props.first ? '2rem' : '-3.1rem',
			marginBottom: 0,
			fontWeight: '700'
		},
		underline: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: 0,
			marginBottom: '3.1rem'
		},
		underline_blue: {
			backgroundColor: '#00FAFE',
			width: '3.75rem',
			height: '0.5rem',
			marginRight: '0.19rem',
			marginTop: 0
		},
		underline_pink: {
			backgroundColor: '#E24ADF',
			width: '3.75rem',
			height: '0.5rem',
			marginLeft: '0.25rem',
			marginTop: 0
		}
	};

	return (
		<div>
			<h2 style={styles.title} className="section-title">
				{props.children}
			</h2>
			<div style={styles.underline} className="title-underline">
				<div style={styles.underline_blue} className="underline-blue" />
				<div style={styles.underline_pink} className="underline-pink" />
			</div>
		</div>
	);
};

export default SectionTitle;
