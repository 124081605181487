import { useState } from 'react';

const SocialIcon = (props) => {
	const [ hovering, setHovering ] = useState(false);

	const styles = {
		icon: {
			width: '2rem',
			transition: '0.3s',
			transform: hovering ? 'scale(1.2)' : '',
			color: 'white'
		},
		icon_link: {
			marginLeft: '1.5rem',
			marginRight: '1.5rem'
		}
	};

	return (
		<a style={styles.icon_link} href={props.href} rel="noreferrer noopener" target="_blank">
			<img
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => {
					setHovering(false);
				}}
				style={styles.icon}
				src={props.src}
				alt={props.alt}
			/>
		</a>
	);
};

export default SocialIcon;
