import { useState } from 'react';

const FeaturesCard = (props) => {
	const [ hovering, setHovering ] = useState(false);

	const styles = {
		features_card: {
			border: '0.31rem solid #fff',
			width: props.modal == true ? '30rem' : window.innerWidth <= 700 ? '' : '25rem',
			marginLeft: window.innerWidth <= 700 ? '2rem' : '0.63rem',
			marginRight: window.innerWidth <= 700 ? '2rem' : '0.63rem',
			transition: '0.3s',
			transform: hovering ? 'scale(1.05)' : '',
			marginBottom: window.innerWidth <= 700 ? '1rem' : 0,
			backgroundColor: props.modal == true ? 'black': ''
		},
		card_title: {
			display: 'table',
			color: '#000',
			backgroundColor: '#fff',
			padding: '0.63rem 1.9rem',
			marginTop: -3,
			marginLeft: -3,
			borderBottomRightRadius: '0.63rem'
		},
		card_title_text: {
			margin: 0,
			fontSize: '0.94rem',
			transform: 'translateY(-0.25rem)',
			fontWeight: 'bold',
		},
		card_body: {
			padding: '1.56rem'
		},
		icon: {
			height: '0.94rem',
			marginRight: '0.31rem',
			// transform: 'translateY(0.18rem)',
			display: 'inline-block'
		}
	};

	return (
		<div
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => {
				setHovering(false);
			}}
			style={styles.features_card}
			className="features-card"
		>
			<div style={styles.card_title} className="features-card-title">
				<h5 style={styles.card_title_text}>
					<img style={styles.icon} src={props.iconPath} alt="" />
					{props.title}
				</h5>
			</div>
			<div style={styles.card_body} className="features-card-body">
				{props.children}
			</div>
		</div>
	);
};

export default FeaturesCard;
