import SocialIcon from "./SocialIcon.js";

const Footer = () => {
  const styles = {
    footer: {
      color: "#fff",
      marginTop: "10rem",
    },
    row: {
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      width: "2rem",
      marginLeft: "1.5rem",
      marginRight: "1.5rem",
    },
    copyright: {
      textAlign: "center",
      paddingBottom: "5rem",
    },
    copyright_text: {
      marginBottom: 0,
      marginTop: "2rem",
    },
    policies_text: {
      marginTop: 0,
      fontSize: "0.8rem",
    },
    policies_link: {
      textDecoration: "none",
      color: "#fff",
    },
    apollo: {
      fontSize: "0.8rem",
    },
  };

  return (
    <div id="SOCIALS" style={styles.footer} className="footer">
      <div style={styles.row} className="socials-row">
        <SocialIcon
          src="/icons/twitter.svg"
          alt="Twitter Icon"
          href="https://twitter.com/PriceErrors"
        />
        <SocialIcon
          src="/icons/instagram.svg"
          alt="Instagram Icon"
          href="https://instagram.com/PriceErrors"
        />
        <SocialIcon
          src="/icons/tiktok.svg"
          alt="Tiktok Icon"
          href="https://www.tiktok.com/@priceerrors_"
        />
        <SocialIcon
          src="/icons/twitch.svg"
          alt="Twitch Icon"
          href="https://www.twitch.tv/priceerrors"
        />
        <SocialIcon
          src="/icons/discord.svg"
          alt="Discord Icon"
          href="https://discord.gg/MGp7r8DQXV"
        />
      </div>
      <div style={styles.copyright} className="copyright-row">
        <p style={styles.copyright_text}>
          Copyright © {new Date().getFullYear()} Price Errors{" "}
          <br />
          <span style={styles.apollo}>Powered by Apollo Dashboards, LLC ❤️</span>
        </p>

        <p style={styles.policies_text}>
          <strong>
            <a style={styles.policies_link} href="/policies/privacy">
              Privacy Policy
            </a>
          </strong>{" "}
          |{" "}
          <strong>
            <a style={styles.policies_link} href="/policies/tos">
              Terms of Use
            </a>
          </strong>
          <strong></strong>
        </p>
      </div>
    </div>
  );
};

export default Footer;
