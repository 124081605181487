// import logo from './loading.png';

const Loading = () => {
	const styles = {
		loading: {
			position: 'absolute',
			backgroundColor: '#000',
			zIndex: 100,
			width: '100%',
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		},
		logo: {
			width: '4rem'
		}
	};

	return (
		<div style={styles.loading} className="loading">
			<img style={styles.logo} src='loading.png' alt="Loading Logo" />
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

export default Loading;
