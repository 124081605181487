import SectionTitle from './SectionTitle.js';
import QuestionCard from './QuestionCard.js';

const FAQSection = () => {
	const styles = {
		section: {
			color: '#fff',
			marginTop: window.innerWidth <= 700 ? '8rem' : '12.5rem'
		},
		card_row: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: window.innerWidth <= 700 ? '' : '1.25rem',
			flexDirection: window.innerWidth <= 700 ? 'column' : 'row'
		}
	};

	return (
		<div style={styles.section} id="FAQ" className="section">
			<SectionTitle>{window.innerWidth <= 700 ? '' : 'Frequent'} Questions</SectionTitle>
			<div style={styles.card_row} className="question-card-row">
				<QuestionCard title="Who are we?" iconPath="/icons/question.svg">
					We are a community of experienced deals finders and resellers ready to help you in any way we can
					build a future towards financial freedom. Offering as much value and support as we can, members have
					been able to turn in thousands of dollars in profits from dedicated use of Price Errors exclusive
					Discord server.
				</QuestionCard>
				<QuestionCard title="How do I purchase?" iconPath="/icons/question.svg">
					Price Errors is at all times Sold Out. We want to ensure we do not saturate our exclusive content so
					by capping membership count we are able to ensure all members can profit. However, we do restock
					almost monthly via Twitter, so make sure to follow @PriceErrors and turn Twitter notifications on.
				</QuestionCard>
			</div>
			<div style={styles.card_row} className="question-card-row">
				<QuestionCard title="Can I cancel?" iconPath="/icons/question.svg">
					The simple answer is Yes. Your subscription is fully managed on this dashboard and cancelable at any
					time, day or night. However, we warn you to have second thoughts about cancelling as once you do,
					these spots will be offered during a restock to the thousands attempting to purchase our limited
					memberships.
				</QuestionCard>
				<QuestionCard title="Refund Policy" iconPath="/icons/question.svg">
					All sales are final. We stand by our product and believe all customers will make their investment in us back PLUS profit with even the smallest use of our extensive server. For any further assistance, please don't hesitate to contact our support team.
				</QuestionCard>
			</div>
		</div>
	);
};

export default FAQSection;
