import SectionTitle from './SectionTitle.js';

const AboutSection = () => {
	const styles = {
		section: {
			color: '#fff',
			marginTop: window.innerWidth <= 700 ? '8rem' : '12.5rem'
		},
		logo: {
			width: '9.4rem',
			marginRight: window.innerWidth <= 700 ? 0 : '2.5rem'
		},
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: window.innerWidth <= 700 ? 'column' : 'row'
		},
		text: {
			width: window.innerWidth <= 700 ? '' : '37.5rem',
			textAlign: window.innerWidth <= 700 ? 'center' : 'left',
			marginLeft: '2.5rem',
			marginRight: window.innerWidth <= 700 ? '2.5rem' : 0
		}
	};

	return (
		<div style={styles.section} className="section">
			<SectionTitle>About Us</SectionTitle>
			<div style={styles.container}>
				<div>
					<img style={styles.logo} src="/LOGO.png" alt="Price Errors Logo" />
				</div>
				<div style={styles.text}>
					<p>
						Established in 2018, our team set out with the goal in mind to guide the next generation of
						deals finder, resellers, and investors on a path towards financial freedom.
					</p>
					<p>
						Price Errors is a server dedicated to ensuring members monthly investment fee is returned
						multiple times over with high quality and exclusive content provided daily. Now hosting the
						highest and most exclusive deals bot in the industry, Price Errors cannot be matched!
					</p>
					<p>
						To ensure we do not saturate our content, spaces are HIGHLY limited and only new members are
						accepted during sporadic restocks via Twitter. Follow us and turn notifications on for the
						opportunity to join the winning team!
					</p>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
